.sale-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.sale-section .sale-section-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 330px;
}


@media (max-width: 350px) {
  .sale-section .sale-section-title{
    display: flex;
    flex-direction: column;
  }
}

.sale-section .add-sale{
  height: 50px;
  width: 50px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #27aae1;
  border-radius: 50%;
  font-family: roboto;
  font-size: 2rem;
  color:white;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 2px;
}

.sale-section .clear-button {
  color: white;
  margin: 8px 0px;
  border-radius: 0px 16px 16px 0px;
  padding: 8px;
}

.sale-section .add-sale:hover{
  background-color: #2fade4;
  box-shadow: rgba(0, 0, 0, 0.5) 1px 2px;
}

.sale-section .add-sale:focus{
  background-color: #8ac5df;
  box-shadow: rgba(0, 0, 0, 0.5) 2px 2px;
}

.sale-section .card{
  cursor: pointer;
  height: 10rem;
  min-width: 17rem;
  width: 24rem;
  max-width: 24rem;
  margin: 0.25rem;
  display: flex;
  flex-direction: row;
  background-color: #FDFDFD;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 2px;
  overflow: hidden;
  white-space: nowrap;
}

.sale-section .card:hover{
  box-shadow: rgba(0, 0, 0, 0.5) 2px 4px 2px;
}

.sale-section .card .thumbnail{
  height: 10rem;
  max-width: 50%;
  width: 10rem;
  border-radius: 0 0.5rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #27aae1;
  background-size: cover;
  background-position: center;
}

.sale-section .card .thumbnail .state{
  align-self: flex-end;
  width: 100%;
  padding: 0px;
  color: black;
}

.sale-section .card .informations{
  width: calc(100% - 10rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0.5rem;
}

.sale-section .card .informations .title{
  width: 100%;
  font-size: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: normal;
}

.sale-section .card .informations .model{
  color: #27aae1;
  font-size: 14px;
  margin-bottom: 0.50rem;
}
.sale-section .card .informations .price,
.sale-section .card .informations .kilometers{
  color:rgba(50,50,50,1) ;
  font-size: 16px;
}

.sale-section .card .state.creating {
  color: white;
  font-weight: 100;
  background: #AA0A00;
}
.sale-section .card .state.service {
  background: #0092c7;
  color: white;
}
.sale-section .card .state.product {
  background: #00a32d;
  color: white;
}
.sale-section .card .state.for-sale {
  background: #00C781;
  color: white;
}
.sale-section .card .state.on-hold {
  background: #FFAA15;
}
.sale-section .card .state.sold {
  background: #FF4040;
}

.sale-list{
  min-width: 80%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.sale-list_card-expand {
  animation: expand ease 0.3s;
  -webkit-animation: expand ease 0.3s;
  -moz-animation: expand ease 0.3s;
  -o-animation: expand ease 0.3s;
  -ms-animation: expand ease 0.3s;
}

@keyframes expand {
  0% {
    height: 0rem;
  }
  100% {
    min-height: 5rem;
    height: 10rem;
  }
}

@-moz-keyframes expand {
  0% {
    height: 0rem;
  }
  100% {
    min-height: 5rem;
    height: 10rem;
  }
}

@-webkit-keyframes expand {
  0% {
    height: 0rem;
  }
  100% {
    min-height: 5rem;
    height: 10rem;
  }
}

@-o-keyframes expand {
  0% {
    height: 0rem;
  }
  100% {
    min-height: 5rem;
    height: 10rem;
  }
}

@-ms-keyframes expand {
  0% {
    height: 0rem;
  }
  100% {
    min-height: 5rem;
    height: 10rem;
  }
}