.home{
  min-height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.home .home__section1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-size: cover;
  background-position: center;
  background-image: url(../../assets/img/homebackground.jpg);
  min-height: calc(100vh - 13rem);
  border-bottom: 1rem solid #27aae1;
}

.home .home__text.title{
  margin-left: 4vw;
  font-size: calc(2rem + 1.4vw);
  display: flex;
  align-items: flex-start;
  max-width: 900px;
  color: white;
}

.home .home__text.title span{
  width: 100%;
}

.home .home__icon {
  min-height: 50px;
  min-width: 50px;
  height: 50px;
  width: 50px;
  background-color: #27aae1 ;
  border: solid 1px white;
  padding: 0.25rem;
  box-shadow: rgba(255, 255, 255) 1px 1px 0px;
  margin: 1rem;
  transform: rotate(45deg);
  overflow: hidden;
}
.home .home__icon img{
  height: 100%;
  transform: rotate(-45deg);
}

.home .home__text{
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  line-height: 150%;
  margin: .5rem;
}

.home .home__text.home__text-row{
  flex-direction: column;
  align-items: flex-start;
}

.home .home__text .home__text-content{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: white;
  line-height: 100%;
}

@media (max-width: 600px) {
  .home .home__text.home__text-row{
    align-items: center;
  }

  .home .home__text .home__text-content{
    flex-direction: column;
  }
}

.home .star-review{
  cursor: pointer !important;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:black;
  padding: .5rem 1.25rem;
  border-radius: 2rem 2rem 4rem 4rem;
  font-size: calc(0.8rem + 0.2vw);
  margin-bottom: 1rem;
}

.home .home__subtext{
  justify-self: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  line-height: 110%;
  margin-bottom: 1rem;
}

.home .home__section2 {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: row-reverse;
}

@media (max-width: 785px) {
  .home .home__section2 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.home .home__images {
  width: 600px;
  max-width: 785px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1rem;
  padding-bottom: 1rem;
}

@media (max-width: 785px) {
  .home .home__images {
    width: 100%;
  }
}

.home .home__images__slider{
  margin: 0.15rem;
}

.home .home__images__slider .slider__wrapper {
  width: 100%;
}

.home .home__images__carousel {
  margin: 0.15rem;
  background-color: rgba(255,255,255,0.5);
}

.home .home__links {
  min-width: 50%;
  width: 98%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.home .home__links .text {
  font-size: 1.25rem;
  line-height: 100%;
  color: black;
  text-decoration: underline;
}

.home .home__links .separator {
  height: 0.5rem;
  width: 100px;
  background-color: #27aae1;
  border-radius: 5px;
  margin: 0.75rem;
}

.home .home__info {
  font-size: 1rem;
  text-align: left;
  height: 100%;
  width: 90%;
  max-width: 900px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.communication{
  font-size: 1rem;
  text-align: left;
  height: 100%;
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.communication .home__subtext{
  background: white;
  box-shadow: rgba(0, 0, 0, 0.20) 1px 2px;
  padding: 0.25rem;
}

@media (max-width: 785px) {
  .communication{
    flex-direction: column-reverse;
  }  
  .communication .home__subtext{
   width: 100%;
   padding: 0;
  }
}

.social{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.social a{
  display: flex;
  align-items: center;
  color: white;
  background-color: #27aae1;
  border-radius: 10px;
  padding: 5px;
  margin: 0.25rem;
}

@media (max-width: 770px) {
  .home .home__images {
    flex-direction: column;
  }

  
  .home .home__info {
    flex-direction: column-reverse;
    width: 98%;
  }
}


@media (min-width: 601px) {
  .speed-rows {
    width: 100%; height: 45px; position: relative;
    overflow: hidden;
  }
}

@media (max-width: 600px) {
  .speed-rows {
    width: 100%; height: 140px; position: relative;
    overflow: hidden;
  }
}