.sale-modal {
  min-width: 25vw;
  min-height: 50vh;
  overflow: auto;
  max-height: 75vh;
}

.sale-form {
  display: flex;
  flex-direction: column;
  max-height: 80%;
}

.sale-form .fields {
  display: flex;
  flex-direction: column;
  width: 95%;
}

.sale-form .fields .fields-column {
  display: flex;
  flex-direction: row;
}

.sale-form .fields .fields-column div {
  margin-left: 0.5rem
}

.sale-form .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.sale-form .edit-buttons {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 100%;
  max-width: 340px;
  justify-content: space-evenly;
}

.sale-form .edit-buttons Button{
  margin-left: 1rem;
  height: 40px;
  width: 90px;
}


.sale-form .buttons .delete-button {
  color: #AA0A00;
  border: 2px solid #AA0A00;
}

@media (max-width: 575px) {
  .sale-form .fields .fields-column {
    display: flex;
    flex-direction: column;
  }
  .sale-form .buttons {
    flex-direction: column;
  }
  .sale-form .edit-buttons {
    align-self: flex-end;
  }
  .sale-form .edit-buttons Button{
    margin: 0;
    padding: 0;
    height: 40px;
    width: 60px;
    font-size: 15px;
  }
}

.upload__image-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload__image-list{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.upload__image-item {
  width: 33%;
  position: relative;
}

.upload__image-item-btns{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.upload__image-item-btns button{
  width: 100px;
  background: #ffffff72;
  border: black 1px solid;
  height: 25px;
}