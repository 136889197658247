.auth {
  text-align: center;
  position: absolute;
  bottom: 8px;
  color: gray;
  right: 16px;
  z-index: 5;
  cursor: pointer;
}

.auth.auth_sign-out{
  color: red;
}