.sale-modal.view-sale {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  overflow-x: hidden;
}

.sale-modal.view-sale::-webkit-scrollbar {
  display: none;
}

.sale-modal.view-sale .view-sale-carousel{
  max-width: 100%;
  min-height: 300px;
  height: 300px;
  max-height: 300px;
}

.sale-modal.view-sale .view-sale-carousel button{
  padding: 0px;
}


.sale-modal.view-sale .view-sale-header{
  width: 100%;
  padding-top: 2rem;
  font-size: 22px;
  border-bottom: 3px solid #27aae1;
}

.sale-modal.view-sale .view-sale-infos{
  width: 100%;
  padding-top: 0.5rem;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sale-modal.view-sale .view-sale-subheader{
  color: #27aae1;
  font-size: 16px;
}

.sale-modal.view-sale .view-sale-infos .state{
  font-size: 16px;
  text-align: center;
  min-width: 58px;
  border-radius: 10px;
  padding: 3px;
  color: black;
}

.sale-modal.view-sale .view-sale-infos .state.creating {
  color: white;
  font-weight: 100;
  background: #AA0A00;
}
.sale-modal.view-sale .view-sale-infos .state.service {
  background: #0092c7;
  color: white;
}
.sale-modal.view-sale .view-sale-infos .state.product {
  background: #00a32d;
  color: white;
}
.sale-modal.view-sale .view-sale-infos .state.for-sale {
  background: #00C781;
  color: white;
}
.sale-modal.view-sale .view-sale-infos .state.on-hold {
  background: #FFAA15;
}
.sale-modal.view-sale .view-sale-infos .state.sold {
  background: #FF4040;
}

.sale-modal.view-sale .view-sale-other-infos {
  align-self: flex-start;
  color: rgb(80, 80, 80);
  font-size: 1rem;
}

.sale-modal.view-sale .view-sale-description {
  padding-top: 1rem;
  width: 100%;
  max-width: 300px;
  font-size: 13px;
  text-align: start;
}