.speed-row-1{
  background: #f94144;
}
.speed-row-2{
  background: #f3722c;
}
.speed-row-3{
  background: #35bb93;
}
.speed-row-1:hover{
  background: #fc292c;
}
.speed-row-2:hover{
  background: #f36315;
}
.speed-row-3:hover{
  background: #43aa8b;
}

.speed-row span a{
  color:white;
  text-decoration-line: none;

}

.speed-row span{
  margin-right: 10vw;
  color:white;
}

.speed-row{
  position: absolute;
  display: flex;
  align-self: self-start;
  justify-content: flex-end;
  align-items: center;
  width: 110%;
  height: 45px;
}

@media (min-width: 601px) {
  .speed-row span{
    font-size: calc(0.5rem + 0.8vw);
    margin-right: 7vw;
  }

  .speed-row-1{
    left: -10vw;
    top: 0px;
    animation: speed-row-1 linear 0.5s;
  }
  .speed-row-2{
    left: -44vw;
    top: 0px;
    animation: speed-row-2 linear 0.5s;
  }
  .speed-row-3{
    left: -77vw;
    top: 0px;
    animation: speed-row-3 linear 0.5s;
  }
  
  @keyframes speed-row-1 {
    0% {
      left: -110vw;
    }
    100% {
      left: -10vw;
    }
  }

  @keyframes speed-row-2 {
    0% {
      left: -110vw;
    }
    100% {
      left: -44vw;
    }
  }

  @keyframes speed-row-3 {
    0% {
      left: -110vw;
    }
    100% {
      left: -77vw;
    }
  }
}


@media (max-width: 600px) {
  .speed-row span{
    font-size: calc(1.3rem + 1.4vw);
    margin-right: 15vw;
  }

  .speed-row-1{
    top: 0px;
    left: 0;
  }
  .speed-row-2{
    top: 45px;
    left: 0;
  }
  .speed-row-3{
    top: 90px;
    left: 0;
  }
  
  .speed-row {
    animation: speed-row-appearance linear 0.5s;
    border-radius: 0% 100% 10% 85% / 100% 0% 100% 0% ;
  }
  
  @keyframes speed-row-appearance {
    0% {
      left: -110vw;
    }
    100% {
      left: 0;
    }
  }
}