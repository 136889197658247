.app-footer{
  display: flex;
  justify-content: center;
  background-color:transparent;
  width: 100%;
  height: calc( 3vw + 9rem );
  max-height: 13rem;
  position: fixed;
  bottom:0px;
  overflow: hidden;
  z-index: 2;
}


.app-footer .app-footer__menu{
  background: linear-gradient(180deg, transparent 25%, rgba(25,25,25,1) 50.2%);
  height: calc( 3vw + 9rem );
  max-height: 13rem;
  position: absolute;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-footer__menu__email,
.app-footer__menu__phone {
  color:white;
  display: flex;
  font-size: calc(10px + 0.5vw);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 100%;
}

.app-footer__menu__phone {
  margin-right: 12vw;
}

.app-footer__menu__email  {
  margin-left: 12vw;
}

.app-footer__menu__phone-icon,
.app-footer__menu__email-icon {
  min-height: 3rem;
  min-width: 3rem;
  height: 4vw;
  width: 4vw;
  max-height: 4rem;
  max-width: 4rem;
  transition: 0.5s;
  margin-bottom: 0.5rem;
}

.app-footer__menu a{
  color:white;
  text-decoration: none;
}


.app-footer__menu__phone-icon {
  height: 4.2vw;
  width: 4.2vw;
}

.app-footer__menu__phone-icon:hover,
.app-footer__menu__email-icon:hover {
  height: 5vw;
  width: 5vw;
  max-height: 5rem;
  max-width: 5rem;
}

@media (max-width: 600px) {
  .app-footer .app-footer__menu{
    justify-content: space-between;
    padding: 5px;
  }
}

@media (max-width: 295px) {
  .app-footer .app-footer__menu{
    justify-content: space-between;
    padding: 1px;
  }

  .app-footer__menu__email-icon {
    min-height: 2rem;
    min-width: 2rem;
  }

  .app-footer__menu__phone-icon {
    min-height: 2rem;
    min-width: 2rem;
  }
  .app-footer__menu a{
    font-size: 10px;
  }
}

.app-footer .maps-box{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  background-color:rgba(25,25,25,1);
  border: 4px solid white;
  min-height: 5.8rem;
  min-width: 5.8rem;
  height: 8vw;
  width: 8vw;
  max-height: 8rem;
  max-width: 8rem;
  transform: rotate(45deg);
  transition: 0.5s;
  z-index: 2;
}

.app-footer .maps-box:hover{
  height: 9vw;
  width: 9vw;
  max-height: 8.5rem;
  max-width: 8.5rem;
}

.app-footer .maps-box .maps-box__icon{
  height: 64%;
  width: 64%;
  transform: rotate(-45deg);
  transition: 0.5s;
}

.app-footer .maps-box .maps-box__text1{
  position: absolute;
  top: 0px;
}

.app-footer .maps-box .maps-box__text2{
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: left;
  padding-left: 0.5rem;
  height: 1rem;
  overflow: hidden;
}