.modal {
  border-radius: 0;
}

@media (min-width: 770px) {
  .modal {
    border: 10px solid #27aae1;
  }  
}


.modal .modal_close-button {
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 50%;
  font-family: sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  margin: 0.5rem;
  background-color: #27aae1;
  z-index: 3;
  color: white;
}