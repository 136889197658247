body{
  background: #27aae1;
}

.App {
  background-image: linear-gradient(175deg, rgba(255,255,255,1) 60%, #27aae1 60.2%);
}
@keyframes animateBg {
  0% { background-position: 0% 100%; }
  50% { background-position: 0% 60%; }
  100% { background-position: 0% 100%; }
}


.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

.App-content{
  overflow: auto;
  padding-bottom: calc( 3vw + 9rem );
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.App-content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
}
.App-content::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(25,25,25,1) ;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.show-in-mobile{
  display: flex;
}

.hide-in-mobile{
  display: none;
}
@media (hover: hover) {
  .show-in-mobile{
    display: none;
  }
  
  .hide-in-mobile{
    display: flex;
  }
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
