
.app-header.isAdmin {
  background: linear-gradient(180deg, green 15px, transparent 15px);
}

.app-header.isSuperAdmin {
  background: linear-gradient(180deg, red 15px, transparent 15px);
}

.app-header {
  z-index: 2;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  background: linear-gradient(180deg, rgba(25,25,25,1) 15px, #3db4e7 15px,#3db4e7 25px,  transparent 25px);
  width: 100%;
  min-height: 7rem;
  height: calc(6.5rem + 5vw);
  max-height: 9rem;
}

@media (max-width: 600px){
  .app-header .nav-bar .app-header {
    min-height: 6.5rem;
    font-size: calc(1rem + 0.8vw);
  }
}

.app-header .nav-bar{
  padding-top: 0px;
  padding-bottom: 10px;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
}

.menu-bar-fulling {
  width: 50vw;
  background-color: #27aae1;
  min-height: 6rem;
  height: calc(5vw + 5rem);
  max-height: 7rem;
}

.app-header .nav-bar Button {
  color:white;
  font-size: calc(13px + 0.8vw);
  min-width: 6rem;
  width: 15vw;
  max-width: 11rem;
  min-height: 6rem;
  height: calc(5vw + 5rem);
  max-height: 7rem;
  z-index: 2;
  -webkit-transition: all .25s;
  -moz-transition: all .25s;
  -o-transition: all .25s;
  transition: all .25s;
}

.app-header .nav-bar Button.button-left{
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 9px 20px 20px -7px;
}
.app-header .nav-bar Button.button-right{
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.2) -9px 20px 20px -7px;
}

.nav-bar Button:hover{
  background-color: #3db4e7;
  max-height: 8rem;
}

.app-header .nav-bar Button.selected-tab{
  background-color: white;
  color:#27aae1;
  border-width: 2px;
}

.app-header .nav-bar .logo {
  margin-top: 2rem;
  align-self: flex-start;
  height: 9vw;
  width: 25vw;
  min-height: 40px;
  min-width: 95px;
  max-height: 70px;
  max-width: 190px;
  border-radius: 0;
  z-index: 2;
}

.app-header .side-bar {
  position: absolute;
  width: 100%;
  height: 15px;
  top:0px;
  background-color: rgb(20,20,20);
}

title,
description {
  display: none;
}