.services{
  background-image: linear-gradient(185deg, rgba(255,255,255,1) 55%, #27aae1 55.2%);
  padding-top: 0.15rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.services .services-row{
  width: 100%;
  max-width: 950px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.services .services-row .card{
  cursor: pointer;
  height: 26vh;
  width: 35vh;
  margin: 0.5rem;
  box-shadow: rgb(0 0 0 / 20%) 3px 3px 3px 1px;
  border-radius: 0;
}

.truck-image,
.trailer-image,
.h24service-image,
.custom-image {
  background-position: center;
  background-size: cover;
}

.services .services-row .card .image {
  -webkit-transition: all 5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.services .services-row .card:hover .image {
  transform: scale(1.2);
}

.services .services-row .card footer {
  z-index: 1;
  height: 20%;
  background-color: white;
  justify-content: center;
}
.services .services-row .card footer.custom-footer {
  background: linear-gradient(47deg, rgb(255, 74, 74) 0%,  rgb(255, 74, 74) 20%, rgb(255, 195, 43) 50%,  rgb(255, 74, 74)  80%,  rgb(255, 74, 74) 100%);
  background-size: 800% 800%;
	animation: gradient 8s linear infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}

.services .services-row .card footer .card-footer__name{
  font-size: 20px;
}

.services .services-row .card footer .card-footer__info{
  color: #27aae1;
  text-transform: uppercase;
}

@media (max-width: 880px) {
  .services .services-row.row1,
  .services .services-row.row2{
    width: 100%;
  }

  .services .services-row .card {
    max-height: 50vw;
  }
}

@media (max-width: 420px) {
  .services .services-row .card footer .card-footer__name{
    font-size: 14px;
  }

  .services .services-row .card{
    margin: 0.2rem;
    margin-bottom: 1rem;
  }

  .services .services-row .card footer {
    font-size: 11px;
    padding: 3px;
    line-height: 110%;
  }
}

.services-modal{
  width: 485px;
  max-width: 100%;
  overflow: auto;
  max-height: 75vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.services-modal::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 2px;
}
.services-modal::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #27aae1;
}

.services-modal .modal-header{
  font-size: 24px;
  padding-bottom: 2rem;
  padding-top:1rem;
}
.services-modal .modal-header-description{
  font-size: 14px;
}

.services-modal .modal-service{
  padding: 0.25rem;
  font-size: 16px;
}

.services-modal .modal-divider{
  width: 0.25rem;
  height: 7rem;
  background:#27aae1;
}

.services-modal .modal-service .modal-service-price{
  font-size: 14px;
  color: #27aae1;
}

.services-modal .modal-note{
  padding-top: 1rem;
}

.services-modal .services-carousel{
  background-color:#27aae1;
  margin-top: 1rem;
  align-self: center;
  border-radius: 12px;
  border: 0.5rem solid #27aae1;
}